import React, { lazy, Suspense } from "react";
import "@fontsource/poppins";
import "../src/App.css";
import Header from "./components/Header";
import Body from "./components/Body/Body";
import { CookieSetting } from "cookiesetting-component";
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";

const Footer = lazy(() => import("./components/Footer/Footer.js"));
const Rates = lazy(() => import("./components/Rates/Rates.js"));
const Joaquin = lazy(() => import("./components/Homebuyingfeature/Homebuyingfeature.js"));
const Assistance = lazy(() =>
  import("./components/ExploreAssistance/Assistance.js")
);
const Market = lazy(() => import("./components/Market/Market.js"));
export default function App() {
  const appVersion = appversion.version;

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="bodyContainer">
        <Header fetchpriority="high" />
        <>
          <Body />
          <Suspense fallback={<div />}>
            <Market />
          </Suspense>
          <Suspense fallback={<div />}>
            <Assistance />
          </Suspense>
          <Suspense fallback={<div />}>
            <Rates />
          </Suspense>
          <Suspense fallback={<div />}>
            <Joaquin />
          </Suspense>
          <Suspense fallback={<div />}>
            <Footer />
          </Suspense>
          <CookieSetting 
            privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
            configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
            analytics={process.env.REACT_APP_ANALYTICS_PATH}
          />
        </>
      </div>
    </CacheBuster>
  );
}
